<template>
  <v-container>
    <go-back-btn :routeToGo="routeToGo" />
    <PageHeader :title="title" class="mb-3" />
    <v-row>
      <!-- Filtros -->
      <v-col cols="12">
        <v-card flat class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-form
              v-model="isFormValid"
              ref="clientes-filters-form"
              id="clientes-filters-form"
              @submit.prevent="applyFilters()"
            >
              <v-row>
                <!-- Fecha desde -->
                <v-col cols="12" sm="6" md="3" class="pb-0">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaDesdeSelected"
                        label="Fecha desde"
                        :append-icon="calendarIcon"
                        v-bind="attrs"
                        outlined
                        autocomplete="off"
                        clearable
                        v-mask="'##/##/####'"
                        dense
                        hint="Formato DD/MM/AAAA"
                        onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                        @blur="fechaDesde = parseDateToIso(fechaDesdeSelected)"
                        @keypress="menu1 = false"
                        v-on="on"
                        :rules="[
                          ...rules.required,
                          ...(fechaHasta
                            ? rules.validDateRange(fechaDesde, fechaHasta)
                            : []),
                          ...rules.validDate,
                        ]"
                        aria-autocomplete="off"
                      ></v-text-field>
                    </template>

                    <v-date-picker
                      v-model="fechaDesde"
                      no-title
                      locale="es-AR"
                      :first-day-of-week="1"
                      show-adjacent-months
                      @change="fechaDesdeSelected = formatDate(fechaDesde)"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- Fecha hasta -->
                <v-col cols="12" sm="6" md="3" class="pb-0">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaHastaSelected"
                        label="Fecha hasta"
                        onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                        :append-icon="calendarIcon"
                        hint="Formato DD/MM/AAAA"
                        @blur="fechaHasta = parseDateToIso(fechaHastaSelected)"
                        v-mask="'##/##/####'"
                        autocomplete="off"
                        @keypress="menu2 = false"
                        outlined
                        dense
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        :rules="[
                          ...rules.required,
                          ...(fechaHasta
                            ? rules.validDateRange(fechaDesde, fechaHasta)
                            : []),
                          ...rules.validDate,
                        ]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaHasta"
                      locale="es-AR"
                      show-adjacent-months
                      :first-day-of-week="1"
                      no-title
                      @change="fechaHastaSelected = formatDate(fechaHasta)"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- Tipo de consulta -->
                <v-col cols="12" sm="6" md="3" align-self="center" class="pb-0">
                  <v-text-field
                    v-model="tipoConsulta"
                    dense
                    outlined
                    label="Consulta"
                    single-line
                  ></v-text-field>
                </v-col>
                <!-- Nombre de cliente -->
                <v-col cols="12" sm="6" md="2" align-self="center" class="pb-0">
                  <v-text-field
                    v-model="clienteNombre"
                    label="Cliente"
                    single-line
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <!-- Aplicar -->
              </v-row>
              <v-row class="mr-1" justify="end">
                <v-col cols="12" sm="12" md="1" class="py-4 pr-5">
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    :dark="isFormValid"
                    type="submit"
                    form="clientes-filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <!-- Data table -->
    <v-card>
      <v-data-table
        class="elevation-2"
        :headers="datosClientesHeaders"
        :items="datosClientes"
        :loading="loading"
        :expanded.sync="expanded"
        show-expand
        :single-expand="singleExpand"
        no-data-text="No hay datos disponibles para mostrar"
        :search="searchConsulta"
        item-key="datoId"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="4" align-self="center" class="pt-5">
                <v-text-field
                  v-model="searchConsulta"
                  clearable
                  label="Buscar"
                  single-line
                  :append-icon="searchIcon"
                ></v-text-field>
              </v-col>
              <v-col cols="8" align="end" class="pr-5 pt-4 text-right">
                <vue-excel-xlsx
                  :data="datosClientes"
                  :columns="datosClientesColumns"
                  :fileName="'Datos de clientes'"
                  :fileType="'xlsx'"
                  :sheetName="'sheetname'"
                >
                  <v-tooltip left max-width="40%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="pr-4 pt-1"
                        size="30px"
                        color="#002942"
                        v-bind="attrs"
                        v-on="on"
                        >{{ excelIcon }}</v-icon
                      >
                    </template>
                    <span> Exportar a excel </span>
                  </v-tooltip>
                </vue-excel-xlsx>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            {{ item.observaciones }}
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  directives: { mask },
  components: { PageHeader, GoBackBtn },
  name: "EnvioDatosClientes",
  data() {
    return {
      title: "Envío de datos de clientes",
      isFormValid: false,
      loading: false,
      routeToGo: "/",
      rules: rules,
      menu1: false,
      menu2: false,
      fechaDesde: null,
      fechaDesdeSelected: null,
      fechaHasta: null,
      fechaHastaSelected: null,
      tipoConsulta: null,
      clienteNombre: null,
      singleExpand: false,
      expanded: [],
      searchConsulta: null,
      searchIcon: "mdi-magnify",
      excelIcon: "mdi-microsoft-excel",
      calendarIcon: "mdi-calendar",
      datosClientesHeaders: [
        {
          text: "Cliente",
          align: "start",
          sortable: true,
          value: "clienteNombre",
        },
        { text: "Consulta", sortable: false, value: "consulta" },
        { text: "Fecha", sortable: false, value: "fecha" },
        { text: "Dato", sortable: false, value: "dato" },
        { text: "Valor 1", sortable: false, value: "nValor1", align: "end" },
        {
          text: "Valor 2",
          sortable: false,
          value: "nValor2",
          align: "end",
        },
        { text: "Valor 3", sortable: false, value: "nValor3", align: "end" },
        {
          text: "Observaciones",
          value: "data-table-expand",
          align: "center",
        },
      ],
      datosClientesColumns: [
        { label: "Cliente", field: "clienteNombre" },
        { label: "Consulta", field: "consulta" },
        { label: "Fecha", field: "fecha" },
        { label: "Dato", field: "dato" },
        { label: "Valor 1", field: "nValor1" },
        { label: "Valor 2", field: "nValor2" },
        { label: "Valor 3", field: "nValor3" },
        { label: "Observaciones", field: "observaciones" },
      ],
      datosClientes: [],
    };
  },
  methods: {
    ...mapActions({
      fetchDatosAdicionalesClientes:
        "clientesData/fetchDatosAdicionalesClientes",
    }),
    async applyFilters() {
      this.loading = true;
      const data = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        tipoConsulta: this.tipoConsulta != null ? this.tipoConsulta : "",
        clienteNombre: this.clienteNombre != null ? this.clienteNombre : "",
      };
      const response = await this.fetchDatosAdicionalesClientes(data);
      this.datosClientes = response;
      this.loading = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
  },
};
</script>

<style></style>
